/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */

import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import SEO from "components/SEO";
import "./about.css";
import Image from "gatsby-image";
import { rhythm } from "utils/typography";

function AboutPage({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout
      location={location}
      title={siteTitle}
      showHeader={true}
      showFooter={true}
      fullWidth={false}
    >
      <SEO title="About" description="Gagandeep rangi's about page " />

      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(26)
        }}
      >
        <div className="about-flex">
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt="Author Image"
            style={{
              minWidth: 50,
              borderRadius: `100%`
            }}
            imgStyle={{
              borderRadius: `50%`
            }}
          />
          <div className="text-container">
            <h3>
              Hi{" "}
              <span role="img" aria-label="hi">
                👋
              </span>
            </h3>
            <p>My name is Gagandeep Singh Rangi</p>
          </div>
        </div>
        <div>
          <h2>About Me</h2>
          <ul>
            <li>I like making lists.</li>
            <li>I write about things to better understand them.</li>
            <li>I love playing and watching all sports.</li>
            <li>I'm always trying something new, I rarely find myself bored.</li>
            <li>
              I live by{" "}
              <a href="https://rangi.one/inputs" target="_blank" rel="noreferrer">
                this idea.
              </a>{" "}
            </li>
            <li>
              Socials:{" "}
              <a href="https://instagram.com/gagandeeprangi1" target="blank">
                Instagram
              </a>
              {/* ,&nbsp;
              <a href="https://twitter.com/gagandeeprangi1" target="blank">
                Twitter
              </a> */}
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic-2.jpg/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

